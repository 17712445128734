import { trackView as trackViewByArgonaut, trackEvent, clearView, init } from '@argonaut/analytics';

const trackView = (viewDataGetter) => {
  clearView();
  trackViewByArgonaut(viewDataGetter);
};

export default {
  trackView,
  trackEvent,
  init,
};
